import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import {Marker} from "@react-google-maps/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare }  from "@fortawesome/free-brands-svg-icons";
import { faInstagram }  from "@fortawesome/free-brands-svg-icons";
import { faTwitter }  from "@fortawesome/free-brands-svg-icons";


const containerStyle = {
    width: '100%',
    height: '100%'
};

const center = {
    lat: -34.990408,
    lng: -71.234445
};

function Map() {

    return (
        <LoadScript
            googleMapsApiKey="AIzaSyD3GRJt57VEKsRrpXPFsxCNyIDAV5Vo4m4"
            key="AIzaSyD3GRJt57VEKsRrpXPFsxCNyIDAV5Vo4m4"
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={17}
            >
                <Marker position={center}/>
            </GoogleMap>
        </LoadScript>
    )
}

export default function Contact() {
    return (
        <div id={"sectiond"} className={"contact"}>
            <Container className={"d-container"} fluid>
                <Col className={"d-col"} sm={12}>
                    <Map/>
                </Col>
                <Col className={"d-col"} sm={12}>
                    <footer>
                        <Container className={"zero-padding containercenter"} fluid>
                            <Col className={"zero-padding"} md={{ span: 4, offset: 4}}>
                                <div className="text-center">
                                    <strong><h3>Impretecn</h3></strong>
                                    <strong>Visítanos</strong>
                                    <p>Avenida Buen Pastor #50 - Curicó, Maule <br/>
                                    fono: (75) 222 3383</p>
                                    <div className={"divider"}/>
                                    <strong>Horario de atención</strong>
                                    <p>
                                        Lunes a Viernes <br/>
                                        de 9:00 a 13:00 hrs. y de 15:00 a 18:00 hrs.
                                    </p>
                                    <Row className={"icons-row"}>
                                    <strong>Síguenos en redes sociales: </strong>
                                        {/*<a href="https://www.google.com" target="_blank" rel="noopener noreferrer">*/}
                                        {/*    <FontAwesomeIcon icon={faFacebookSquare} className={"footer-icon"} />*/}
                                        {/*</a>*/}
                                        <a href="https://www.instagram.com/impretecn.cco/" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faInstagram} className={"footer-icon"}/>
                                        </a>
                                        {/*<a href="https://www.google.com" target="_blank" rel="noopener noreferrer">*/}
                                        {/*    <FontAwesomeIcon icon={faTwitter} className={"footer-icon"}/>*/}
                                        {/*</a>*/}
                                    </Row>
                                <p>&copy; Impretecn Ltda.</p>
                                </div>
                            </Col>

                        </Container>
                    </footer>
                </Col>

            </Container>
        </div>
    );
}