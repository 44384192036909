import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function OurServices(){

    return(
        <div id="sectionb" className="offset section-b">
            <Container className={"zero-padding"} fluid>
                <Row className={"b-row"} lg={12}>
                    <Col lg={3} md={12} sm={12} className={"b-text"}>
                        <div className="heading-hat"/>
                        <h2 className="section-heading">
                            Nuestros<br/>productos<br/>y servicios
                        </h2>
                        <p>
                            Ofrecemos una amplia variedad de productos y servicios a disposición de
                            nuestros clientes, ofreciendo siempre garantía, disponibilidad y
                            un alto grado de personalización en toda nuestra oferta.
                            <br/>
                            Garantizamos la satisfacción de nuestros clientes mediante la asesoría
                            antes, durante y después del proceso de compra.
                        </p>
                    </Col>

                    <Col lg={3} md={12} sm={12} className="b-col">
                        <div className="b-container">
                            <div className="b-photo-rent"/>
                            <div className="text-box">
                                <h4 className="b-photo-caption"><strong>venta y arriendo</strong></h4>
                                <p>
                                    Fotocopiadoras<br/>
                                    Impresoras<br/>
                                    Tóners<br/>
                                    Insumos de computación
                                </p>
                            </div>
                        </div>
                    </Col>

                    <Col lg={3} md={12} sm={12} className="b-col">
                        <div className="b-container">
                            <div className="b-photo-print"/>
                            <div className="text-box">
                                <h4 className="b-photo-caption"><strong>imprenta</strong></h4>
                                <p>
                                    Papelería corporativa<br/>
                                    Agendas y carpetas<br/>
                                    Todo tipo de timbres<br/>
                                    Sóbres y bolsas<br/>
                                    Talonarios
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} className="b-col">
                        <div className="b-container">
                            <div className="b-photo-tec"/>
                            <div className="text-box">
                                <h4 className="b-photo-caption"><strong>servicio técnico</strong></h4>
                                <p>
                                    Fotocopiadoras<br/>
                                    Todo tipo de impresoras<br/>
                                    Computadores<br/>
                                    Artículos electónicos en general
                                </p>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    );
}