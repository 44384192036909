import React from 'react';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";

export default function OurBusiness(){
    return (
        <Container id="sectiona" className="section-a" md={12} fluid>
            <Col className={"col-photo"} lg={6} sm={0} xs={0}>
                <div className="a-photo"/>
            </Col>
            <Col className="a-content" lg={6} sm={12} xs={12}>
                <div className="row a-title">
                    <div className="col-12">
                        <div className="heading-hat"/>
                        <h2 className={"section-heading"}>nuestra<br/>empresa</h2>
                    </div>
                </div>
                <div className="row a-row zero-padding">
                    <div className={"icon-container"}>
                        <span className={"certificate-icon a-icon"}/>
                        <h4>Experiencia</h4>
                    </div>
                    <p>
                        Llevamos más de quince años de experiencia en el
                        mercado brindando a nuestros clientes los mejores productos y servicios.
                    </p>
                </div>
                <div className="row a-row zero-padding">
                    <div className={"icon-container"}>
                        <span className={"printer-icon a-icon"}/>
                        <h4>Calidad</h4>
                    </div>
                    <p>
                        Nuestra identidad y prestigio a lo largo de los años están
                        fundados en nuestros altos estándares de calidad.
                    </p>
                </div>
                <div className="row a-row zero-padding">
                    <div className={"icon-container"}>
                        <span className={"client-icon a-icon"}/>
                        <h4>El cliente</h4>
                    </div>
                    <p>
                        Todos nuestros procesos están enteramente basados
                        en la satisfacción de nuestros clientes.
                    </p>
                </div>

            </Col>
        </Container>
    );
}