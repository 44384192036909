import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


import ssd from "../img/c-section/ssd.png";
import ink from "../img/c-section/ink.png";
import xerox from "../img/c-section/xerox.png";

import samsung from "../img/c-section/samsung.png";
import kyocera from "../img/c-section/kyocera.png";
import hp from "../img/c-section/hp.png";
import xeroxLogo from "../img/c-section/xerox-logo.png";

const products = [
    {
        "name": "Insumos de Computación",
        "url": ssd
    },
    {
        "name": "Tintas Originales",
        "url": ink
    },
    {
        "name": "Impresoras Láser",
        "url": xerox
    },
]
const brands = [
    {
        "url": samsung
    },
    {
        "url": kyocera
    },
    {
        "url": xeroxLogo
    },
    {
        "url": hp
    },
]

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


export default function OurProducts(){
    return (
        <div id="sectionc" className={"c-section"}>
            <Container fluid>
                <Col className={"c-col"} sm={12}>
                    <Row className={"sm-reverse"} lg={12} md={12} sm={12}>
                        {
                            products.map((product, index) => {
                                return (
                                    <Col lg={3} md={12} sm={12} key={index}>
                                        <div className="product-card">
                                            <img src={product.url} alt=""/>
                                            <p>{product.name}</p>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                        <Col lg={3} md={12} sm={12}>
                            <div className="c-title">
                                <div className="heading-hat"/>
                                <h2>
                                    {"Algunos de nuestros productos"}
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row lg={12} md={12} sm={12}>
                        <Col lg={3} md={12} sm={12}>
                            <div className="c-title">
                                <div className="heading-hat"/>
                                <h2>
                                    {"Marcas Asociadas"}
                                </h2>
                            </div>
                        </Col>
                        <Col className={"brands-carousel"} lg={9} md={12} sm={12}>
                            <Carousel
                                responsive={responsive}
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={2000}
                                swipeable={true}
                                draggable={false}
                                showDots={false}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                            >
                                {
                                    brands.map((brand, index) => {
                                        return (
                                            <div className={"c-logo"} key={index}>
                                                <img src={brand.url} alt=""/>
                                            </div>
                                        )
                                    })
                                }
                            </Carousel>
                        </Col>
                    </Row>
                </Col>
            </Container>
        </div>
    );
}