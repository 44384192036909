import React, {useEffect, useState} from 'react';
import logo from "../img/logo.png";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {Container} from "react-bootstrap";
let Scroll = require('react-scroll');

let Link = Scroll.Link;

export default function Navigation(){
    let [pos, setPos] = useState(0);

    window.addEventListener('scroll', ()=>{
        setPos(window.scrollY);
    })


    useEffect(()=>{
        if(pos===0){
            document.body.style.setProperty("--nav-color", "rgba(0, 0, 0, 0.6)");
        }else{
            document.body.style.setProperty("--nav-color", "rgba(0, 0, 0, 0.15)");
        }
    }, [pos])

    const style = {
        textTransform: 'uppercase',
        fontWeight: '700',
        fontSize: '.7rem',
        letterSpacing:' .1rem',
        background: 'rgba(0, 0, 0, 0.6)',
    }

    return (
        <Container>

            <Navbar collapseOnSelect expand={"lg"} className="navbar-dark" bg={"dark"} fixed="top" style={style}>
                <Navbar.Brand href="#home"><img alt="" src={logo}/></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className={"justify-content-end"}>
                    <Nav>
                        <li className="nav-item">
                            <div className="navbar-selector"/>
                            <Link
                                className={"nav-link"}
                                activeClass="active"
                                to="landing"
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                Inicio
                            </Link>
                        </li>
                        <li className="nav-item">
                            <div className="navbar-selector"/>
                            <Link
                                className={"nav-link"}
                                activeClass="active"
                                to="our-business"
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                Nosotros
                            </Link>
                        </li>
                        <li className="nav-item">
                            <div className="navbar-selector"/>
                            <Link
                                className={"nav-link"}
                                activeClass="active"
                                to="our-services"
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                Servicios
                            </Link>
                        </li>
                        <li className="nav-item">
                            <div className="navbar-selector"/>
                            <Link
                                className={"nav-link"}
                                activeClass="active"
                                to="our-products"
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                Productos
                            </Link>
                        </li>
                        <li className="nav-item">
                            <div className="navbar-selector"/>
                            <Link
                                className={"nav-link"}
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                Contacto
                            </Link>
                        </li>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    );
}