import React from 'react';

import Carousel from 'react-multi-carousel';
import Button from 'react-bootstrap/Button';

import photo1 from "../img/hw.jpg";
import photo2 from "../img/carousel2.jpg";
import photo3 from "../img/carousel3.png";

let Scroll = require('react-scroll');

let Link = Scroll.Link;

// import Slider from 'react-slick';

const slides = [
    {
        url: photo3,
        title: "Imprenta",
        subtitle: "Papelería corporativa, impresiones y más"
    },
    {
        url: photo1,
        title: "Soporte técnico",
        subtitle: "El mejor soporte técnico de la zona"
    },
    {
        url: photo2,
        title: "Impresoras Láser",
        subtitle: "Venta, arriendo y reparación de impresoras láser"
    },
]

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export default function AppCarousel(){

    return (
        <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            swipeable={true}
            draggable={false}
            showDots={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            // customTransition={"all .6s"}
        >
            {
                slides.map((slide, index) => {
                    return (
                        <div className="landing" key={index}>
                            <div className="home-wrap">
                                <img className={"carousel-img"} src={slide.url} alt=""/>
                                <div className={"carousel-caption caption"}>
                                    <h3><strong>{slide.title}</strong></h3>
                                    <p>{slide.subtitle}</p>
                                    <Link
                                        activeClass="active"
                                        to="our-services"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                    >
                                        <Button className={"button-landing"} variant="outline-primary" >
                                            CONOCE MÁS
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </Carousel>
    );
}
