import React from 'react';

import Navigation from "./components/navigation";
import AppCarousel from "./components/carousel";
import OurBusiness from "./components/our_business";
import OurServices from "./components/our_services";
import OurProducts from "./components/our_products";
import Contact from "./components/contact";

let Scroll = require('react-scroll');
let Element = Scroll.Element;

function App() {
    return (
        <div className={"body"} data-spy="scroll" data-target="navbarResponsive">
            <div id="home" className="offset">
                <Navigation/>
                <Element name={"landing"}>
                    <AppCarousel/>
                </Element>
                <Element name={"our-business"}>
                    <OurBusiness/>
                </Element>
                <Element name={"our-services"}>
                    <OurServices/>
                </Element>
                <Element name={"our-products"}>
                    <OurProducts/>
                </Element>
                <Element name={"contact"}>
                    <Contact/>
                </Element>
            </div>
        </div>
    );
}

export default App;
